import React from "react"
import imgQuizCover from "../../assets/images/gen-mars/mars-quiz/cover.png"

const IQQuestionsReview = ({ questions }) => {

    const getClassName = (optionId, selectedOptionId, correctOptionId) => {

        if (optionId == correctOptionId) {
            return "w-100 text-highlight-brown";
        }
        if (selectedOptionId == optionId && optionId != correctOptionId) {
            return "w-100 text-highlight-red";
        }

        return "";
    }

    return (
        <>
            {
                questions.map((question, index) => {

                    return (
                        <fieldset className="uk-fieldset">
                            <div className="uk-margin flex flex-column flex-row-ns flex-start">
                                <div className="w-100 w-10-ns pa2">
                                    {
                                        question.selectedOption == question.correct_option.id ?
                                            <span className="uk-text-lead stacked-card-count font-nostromo uk-text-bold mr2 uk-hidden@s" style={{ fontWeight: "500", color: "white" }}>
                                                ✔
                                        </span>
                                            :
                                            <span className="uk-text-lead stacked-card-count font-nostromo uk-text-bold mr2 uk-visibl@s" style={{ fontWeight: "500", color: "white" }}>
                                                X
                                        </span>
                                    }

                                    <span className="uk-text-lead text-highlight-brown stacked-card-count font-nostromo uk-text-bold" style={{ fontWeight: "500" }}>
                                        {index + 1}
                                    </span>
                                </div>
                                <div className="w-100 w-90-ns pa2">
                                    <div className="uk-text-large text-light mb4">
                                        {question.question}
                                    </div>

                                    {
                                        question.options.map(option => (
                                            <>
                                                <label className={getClassName(option.id, question.selectedOption, question.correct_option.id)}><input
                                                    className="uk-radio"
                                                    type="radio"
                                                    name={`option-${question.id}`}
                                                    checked={option.id === question.correct_option.id || option.id === question.selectedOption}
                                                    value={option.id} />
                                                    {option.text}</label>
                                                <br />
                                            </>
                                        ))
                                    }
                                </div>

                            </div>
                            <hr className="custom-hr" style={{ borderColor: "black" }} />
                        </fieldset>
                    )
                }
                )
            }


        </>
    )
}

const IQReviewAnswers = ({ iqTestResponses, goBack }) => {
    return <>
        <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{ borderBottom: "5px solid #fff" }}>
            {/* <canvas width="100%" height="100%"></canvas> */}
            <img src={imgQuizCover} alt="" data-uk-cover />
            <div className="uk-overlay uk-position-cover header-overlay"></div>
        </div>
        <section id="iq-test-review" style={{ marginTop: "-100px", position: "relative" }}>
            <div className="large-container pb6">
                <div className="standard-container standard-bg">
                    <div>
                        <h2 className="relative uk-text-center mb5">
                            Review Answers
                    <div className="text-underline-heavy" style={{ marginLeft: "auto", marginRight: "auto" }}></div>
                        </h2>
                    </div>
                    <div className="pa4-ns" style={{ maxWidth: "900px", margin: "auto", backgroundColor: "#262626" }}>
                        <IQQuestionsReview questions={iqTestResponses} />

                    </div>
                    <div className="uk-margin uk-text-center">
                        <button className="uk-button uk-button-default" type="button" onClick={() => {
                            goBack();
                        }}>DONE</button>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default IQReviewAnswers
